.statistic_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}
.main_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 91vh;
  background: #fafafa;
  width: 100vw;
  gap: 20px;
}
.filter {
  width: 100%;
  max-width: 1440px;

  align-items: center;
}

.charts_container {
  width: 100%;
  max-width: 1440px;
}
.statistic_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}
.chart_wrapper {
  width: 100%;
  display: flex;
  height: 376px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: flex-start;
  gap: 15px;
  .bar_chart {
    width: 1141px;
    height: 100%;
    padding: 24px;
    background: #ffffff;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      color: #a9a9a9;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
    button {
      border-radius: 8px;
      background: #2a72dd;
      color: #fff;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      height: 40px;
      border: none;
    }
  }
  .right_chart {
    height: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    .pie_chart {
      width: 275px;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 50px;
    }
    .recent_update {
      width: 360px;
      height: 100%;
      width: 274px;
      background: #ffffff;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      padding: 20px 0px 20px 20px;
      box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
      .header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 1rem;
        width: 200px;
      }
      .activities {
        overflow-y: scroll;
      }
    }
  }
}

.prospector_header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: #222222;
  // margin-top: 24px;
  // margin-bottom: 16px;
}
.goal_container {
  width: 100%;
  max-width: 1440px;
  height: 75vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .goal_not_setup_header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 22px;
    /* identical to box height, or 92% */
    text-align: center;

    color: #646d72;
    margin-bottom: 2rem;
  }
  .button_wrapper {
    width: 150px;
  }
  .project_goal_card {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
}
