.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}
.calc_mobile_not_supported {
  max-width: 70%;
  margin: auto;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.eclipseOne {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
}
.eclipseTwo {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 12px;
}
.aboutImage {
  z-index: 99;
  @media (max-width: 768px) {
    width: 300px;

    object-fit: cover;
    object-position: center;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.page_wrapper {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }
}

.heading_cover_image {
  width: 1050px;
  // height: 286px;
  position: relative;

  background-image: url('../../../public/images/calculatorpage.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  display: flex;
  // align-items: center;
  flex-direction: column;

  overflow: visible;
  padding: 35px;

  @media (max-width: 768px) {
    width: 100%;
    height: 326px;
  }
}

.content_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px 20px;
  }
}

.heading {
  color: #fff;

  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  width: 631px;
}

.content {
  max-width: 980px;
  width: 100%;

  margin-top: 192px;

  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: justify;

  color: #464240;
  @media (max-width: 768px) {
    margin-top: 80px;
  }
}

.man_behind {
  max-width: 980px;
  width: 100%;
  margin-top: 60px;

  height: 397px;

  background: #ffffff;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  gap: 54px;

  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
}

.man_behind_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #2c2c2c;
  margin-bottom: 20px;
}

.man_behind_content {
  max-width: 506px;
  width: 100%;

  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  color: #464240;
  padding-bottom: 50px;
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 150px;

  display: flex;
  justify-content: center;

  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    background: #f7f4f2;

    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.cta_wrapper {
  width: 1080px;
  height: 362px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 341px;
    height: 502px;
    border-radius: 14px;
    padding-left: 36px;
    padding-right: 36px;
    gap: 28px;
  }
}

.cta_image {
  margin-top: -50px;
  border-radius: 8px;
  width: 600px;

  height: 375px;
  @media (max-width: 768px) {
    margin-top: -100px;
    width: 275px;
    height: 312px;
    object-fit: cover;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}
