.add_more {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #2a72dd;
  margin-top: 16px;
  cursor: pointer;
}
.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding-left: 125px;

  gap: 100px;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 46px;
  &_text {
    max-width: 472px;
    height: 126px;
    &_title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 63px;
      /* or 150% */

      color: #2c2c2c;
      margin-bottom: 34px;
    }
    &_description {
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* or 138% */

      color: #464240;
      max-width: 400px;
    }
  }
  &_image {
    // padding-right: 100px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 20px;
    &_text {
      width: 320px;
      height: 178px;
      &_title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: #2c2c2c;
        margin-bottom: 19px;
      }
      &_description {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #464240;
      }
    }
    &_image {
      display: flex;
      justify-content: center;
      width: 320px;
      height: 415px;
      padding: 0px 24px;
      background-color: #ffffff;
      // padding-right: 100px;
      img {
        width: 320px;
        height: 415px;
      }
    }
  }
}

.features {
  margin: auto;
  margin-top: 37px;
  width: 100%;
  max-width: 1050px;
  background-color: #ffffff;
  padding: 31px 37px;
  border-radius: 8px;
  &_header {
    margin-bottom: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;

    /* identical to box height, or 142% */
    // padding-left: 125px;
    color: #2c2c2c;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    width: 320px;
    &_header {
      margin-bottom: 45px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      /* identical to box height, or 142% */
      // padding-left: 125px;
      color: #2c2c2c;
    }
  }
}
.features_category {
  margin: auto;
  margin-top: 37px;
  width: 100%;
  max-width: 1200px;
  &_header {
    margin-bottom: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;

    /* identical to box height, or 142% */
    // padding-left: 125px;
    color: #2c2c2c;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    width: 320px;
    &_header {
      margin-bottom: 45px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      /* identical to box height, or 142% */
      // padding-left: 125px;
      color: #2c2c2c;
    }
  }
}

.card_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  row-gap: 30px;
  column-gap: 30px;
  flex-wrap: wrap;
  margin: auto;
  // padding-left: 125px;
}
.card {
  border: 1px solid #e0e3eb;
  width: 305px;
  height: 160px;
  background: #ffffff;
  border-radius: 12px;
  padding: 21px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  &_shadow {
    width: 327px;
    height: 280px;
    border-radius: 12px;
    position: absolute;
    left: 0;
    top: 0;
    transition-delay: 101.42854021316707ms;
    transition-duration: 249.24795599354906ms;
    transition-property: all;
    &:hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
  &_icon {
    width: 58px;
  }

  &_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 200% */

    color: #2c2c2c;
    margin-top: 10px;
  }
  &_description {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: justify;

    color: #464240;
    // margin-top: 10px;
  }
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 150px;

  display: flex;
  justify-content: center;

  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    background: #f7f4f2;

    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.cta_wrapper {
  width: 1080px;
  height: 362px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 341px;
    height: 502px;
    border-radius: 14px;
    padding-left: 36px;
    padding-right: 36px;
    gap: 28px;
  }
}

.cta_image {
  margin-top: -50px;
  border-radius: 8px;

  height: 375px;
  @media (max-width: 768px) {
    width: 270px;
    object-fit: cover;
    margin-top: -100px;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.calcback {
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}
.calcPrint {
  color: #eb7666;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  gap: 32px;
  cursor: pointer;
}

.calcMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calc_body {
  display: flex;
  // justify-content: space-between;
  gap: 36px;
  margin-top: 24px;
}
.calc_left {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.calc_left_div {
  width: 311px;
  height: 470px;
  border-radius: 8px;
  border: 1px solid #dadada;

  background: #fff;
  padding: 15px 0px;
  .calc_left_div_header {
    color: #373c44;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    span {
      // margin: 0px 15px;
    }
  }
  .calc_left_div_item {
    // height: 45px;
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      padding: 10px 15px;
    }
  }
  .calc_left_div_item:hover {
    background: #f3f5fb;
    border-left: 2px solid #192d73;
  }
  .calc_left_div_item_active {
    height: 45px;
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin: 10px 15px;
    }
    background: #f3f5fb;
    border-left: 2px solid #192d73;
  }
}

.calc_right {
  width: 100%;
}

.calc_right_div_bg {
  width: 100%;
  min-width: 703px;
  // min-height: 1148px;
  .calc_right_div_container {
    display: flex;

    flex-direction: column;
    gap: 24px;
    border: 1px solid #dadada;

    background: #fff;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 24px;
  }

  .header {
    color: #2c2c2c;

    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .form_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #000;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    input {
      width: 206px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #dadada;

      background: #fff;
      color: #3d4348;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      outline: none;
      padding: 8px 12px;
    }
    select {
      width: 206px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #dadada;

      background: #fff;
      color: #3d4348;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      outline: none;
      padding: 8px 12px;
    }
  }
}

.calc_right_div {
  width: 100%;
  min-width: 703px;
  // min-height: 1148px;
  border-radius: 8px;
  border: 1px solid #dadada;

  background: #fff;
  padding: 30px;
  .calc_right_div_container {
    display: flex;

    flex-direction: column;
    gap: 24px;
  }

  .header {
    color: #2c2c2c;

    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .form_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #000;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    input {
      width: 206px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #dadada;

      background: #fff;
      color: #3d4348;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      outline: none;
      padding: 8px 12px;
    }
    select {
      width: 206px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #dadada;

      background: #fff;
      color: #3d4348;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      outline: none;
      padding: 8px 12px;
    }
  }
}

.result {
  border-radius: 8px;
  background: #667ed3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #ffffff;
  .header {
    color: #fff;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 157.143% */
    margin-bottom: 18px;
  }
  .result_section {
    display: flex;
    justify-content: space-between;
  }

  .sub_header {
    color: #fff;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
.calc_right_div_container {
  .disclaimer {
    background: #f3f6ff;
    // border-left: 2px solid #5e78d1;
    color: #192d73;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 14px;
    span {
      font-weight: 500;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    div {
      color: #2c2c2c;

      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 16px;
    }
    p {
      color: #464240;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-align: justify;
    }
  }
}

.input_container {
  position: relative;
  div {
    position: absolute;
    top: 0;

    color: #8b8b8b;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 16px;

    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .input_after {
    right: 0;
  }
  .input_before {
    left: 0;
  }
}
.input_container_big {
  position: relative;
  div {
    position: absolute;
    top: 0;

    color: #8b8b8b;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 16px;

    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .input_after {
    right: 0;
  }
  .input_before {
    left: 0;
  }
}

/* For Webkit browsers like Chrome and Safari */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  textarea,
  input {
    border: none;
    outline: none;
    width: 100%;
  }
  .trh {
    display: flex;
    align-items: center;
    // border-radius: 4px;
    border: 1px solid #dadada;

    background: #f3f5fb;
    .th {
      color: #000;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .tr {
    display: flex;
    border: 1px solid #dadada;
  }
  .th {
    padding: 15px 14px 15px 14px;
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.table_5 {
  .tr {
    .th {
      border-right: 1px solid #dadada;
      width: 20%;
    }
  }
}
.table_60_40 {
  .tr {
    .th:nth-child(1) {
      width: 100%;
      border-right: 1px solid #dadada;
    }
    .th:nth-child(2) {
      width: 40%;
    }
    .th:nth-child(3) {
      width: 40%;
      border-left: 1px solid #dadada;
    }
  }
  .th_bold {
    font-weight: 500;
  }
}
.salutation {
  display: flex;
  gap: 100px;
  input {
    border: none !important;
    outline: none !important;
    border-bottom: 1px solid #dadada !important;
  }
  .salutation_left,
  .salutation_right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  p {
    margin: 0px !important;
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
  }
}

.section_title {
  display: flex;
  align-items: center;
  gap: 18px;

  padding: 8px 12px;
  border-radius: 4px;
  background: #f3f5fb;

  .section_title_char {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #477feb;
    color: #fff;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    width: 100%;
    height: 26px;
    max-width: 26px;
  }
  .section_title_text {
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.divider {
  width: 100%;
  height: 1px;
  margin: 18px 0px;
  background: #e0e0e0;
}
.check_group {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  align-items: center;
  margin: 14px 0px 24px 0px;
}

.check_label {
  display: flex;
  gap: 6px;
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  input {
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
  button {
    display: flex;
    padding: 3px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #d81f1f;
    color: #d81f1f;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background-color: white;
  }
}

.user_detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 70px;
  &_left,
  &_right {
    width: 50%;
  }
}
.section_header {
  color: #2c2c2c;

  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
  text-transform: capitalize;
  margin-bottom: 27px;
}
.radio_group {
  color: #2c2c2c;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  display: flex;
  gap: 22px;
  input {
    margin-right: 9px;
  }
}
.date_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 19px;
  &_row_label {
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    text-transform: capitalize;
  }
  &_input_group {
    display: flex;
    align-items: center;

    gap: 16px;
    .date_row_date {
      width: 154px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #dadada;
      background: #fff;
      padding: 7px 11px;
      color: #8b8b8b;

      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .date_row_range_group {
      display: flex;
    }
  }
}
.date_row_range {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #dadada;
  background: #fff;
  width: 62px;
  height: 38px;

  padding: 7px 11px;

  color: #5a547e;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.date_row_countFrom {
  width: 240px !important;
  height: 38px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dadada;
  background: #e5e7ef;
  color: #5a547e;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  span {
    // max-width: 80%;
    // line-height: 17px;
    // max-height: 30px;
  }
}
.date_row_rangeType {
  width: 200px !important;
  height: 38px;
  flex-shrink: 0;
  border-radius: 0px 3px 3px 0px;
  background: #e5e7ef;
  color: #5a547e;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  span {
    // max-width: 80%;
    // line-height: 17px;
    // max-height: 30px;
  }
}
.merged_select {
  width: auto;
  background: #e5e7ef !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.merged_input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.button_wrapper {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  color: #d81f1f;
}
