.main_container {
  padding-top: 25px;
  background: #f5f5f5;
  //   min-height: 82.5vh;
  // height: 98.5vh;
  width: 100%;
  // padding-bottom: 50px;
  // height: calc(100vh - 122px);

  display: flex;
  flex-direction: column;
  align-items: center;

  .page_heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    color: #222222;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }

  .create_new_campaign {
    width: 100%;
    height: 185px;
    background: #ffffff;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    padding-left: 22px;
    gap: 12px;
    margin-top: 16px;
    justify-content: center;
    margin-bottom: 24px;

    .create_new_campaign_text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 122% */
      color: #222222;
      cursor: pointer;
    }

    .create_new_campaign_description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* or 157% */
      color: #384852;
    }
  }
}

.table {
  border: none;
  width: 964px;
  margin-top: 17px;
  margin-bottom: 90px;
}

.table_header {
  width: 964px;
  height: 50px;
  background: #f0f2f8;
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  padding: 0px 10px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Text_Color */

  color: #667584;
  text-align: left;
}

.table_header_checkbox {
  width: 27px;
  // margin-left: 34px;
  text-align: center;
}

.table_body {
  background-color: #ffffff;
}

.table_body_row {
  height: 58px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #222222;
  cursor: pointer;

  td {
    padding: 15px;
    border: none;
    vertical-align: middle;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #222222;
  }
}

.no_data {
  // max-width: 902px;
  width: 100%;
  height: 271px;

  background: #ffffff;
  border-radius: 8px;

  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .no_campaign_heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    color: #222222;
  }
}

.page_wrapper {
  padding-top: 25px;
  width: 950px;
}
.template {
  width: 95vw;
  background-color: #ffffff;
}
.reset_filter {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2a72dd;
  cursor: pointer;
}
th {
  cursor: pointer;
}
.img_arrow {
  padding-left: 6px;
}
.img_selected {
  transform: rotate(-180deg);
  padding-right: 6px;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  margin-right: 10px;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_body_row:last-child {
  td:first-child {
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
