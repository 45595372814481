.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sc-iveFHk.kPCAqV::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* .sc-dmctIk.kTrxCS::-webkit-scrollbar {
  width: 6px;
  height: 6px;
} */

.sc-iveFHk.kPCAqV::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
}

.sc-iveFHk.kPCAqV::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}
.rdt_TableHeadRow div {
  background: rgb(241, 244, 255);
}

.rdt_TableHead {
  z-index: 1 !important;
}
.rdt_TableBody::-webkit-scrollbar {
  width: 1em;
  height: 6px;
}

.slide-pane__header {
  justify-content: space-between;
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}

.slide-pane__title-wrapper {
  order: 1;
  margin-left: 0px;
}

.slide-pane__close {
  order: 2;
}

.slide-pane__content {
  padding: 0px 0px !important;
  overflow: hidden !important;
}

.slide-pane__title-wrapper {
  margin-left: 16px !important;
}

.slide-pane__title-wrapper h2 {
  max-width: 100% !important;
}

.slide-pane__overlay {
  z-index: 1000;
}

div.DraftEditor-root {
  height: 208px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow-y: auto;
  padding: 0px 10px;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.slide-pane__content .MuiButtonBase-root {
  padding: 12px 16px !important;
}
.react-dropdown-select-content {
  span {
    max-width: 80%;
    /* line-height: 17px; */
    max-height: 30px;
    overflow: hidden;
  }
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: calc(100% - 20px) !important;
}

.PrivateNotchedOutline-root-1 {
  top: 0px !important;
}
.jss1 {
  top: 0px !important;
}
